import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "./ImageUploader";
import BackButton from "../Common/BackButton";
import { setAllInfo } from "redux/container/userSlice";
import Auth from "services/Auth";
import { toast } from "react-toastify";

export default function Nid() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [files1, setFiles1] = useState("");
	const [files2, setFiles2] = useState("");
	const dispatch = useDispatch();

	const nextHandler = async () => {
		let frontPart = new FormData();
		let backPart = new FormData();
		frontPart.append("key", files1[0]);
		backPart.append("key", files2[0]);

		if (files1.length !== 0 && files2.length !== 0) {
			let response = await User.toBucketImage(frontPart);
			if (response.status === 201) {
				let response2 = await User.toBucketImage(backPart);
				if (response2.status === 201) {
					let data = {
						nidFront_image: response.results[0],
						nidBack_image: response2.results[0],
					};
					Auth.setUserInfo({
						nid_back: data.nidBack_image,
						nid_front: data.nidFront_image,
					}).then((res) => {
						console.log(res);
						if (res.success) {
							dispatch(setAllInfo(res.data));
							toast.success("NID Uploaded Successfully!");
							navigate("/address-verify");
						}
					});
				}
			}
		}
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButton>
					<div className='py-5 px-4 verifyBusiness-content'>
						<div className='mb-5'>
							<ImageUploader
								files={files1}
								setFiles={setFiles1}
								bHeight={200}
								placeHolder={ekkLanguage.addressVerify.nidPlaceholder1}
								placeHolder2={ekkLanguage.addressVerify.nidPlaceholder3}
								placeHolder3={ekkLanguage.addressVerify.nidPlaceholder4}
							/>
						</div>
						<div>
							<ImageUploader
								files={files2}
								setFiles={setFiles2}
								bHeight={200}
								placeHolder={ekkLanguage.addressVerify.nidPlaceholder2}
								placeHolder2={ekkLanguage.addressVerify.nidPlaceholder3}
								placeHolder3={ekkLanguage.addressVerify.nidPlaceholder4}
							/>
						</div>
						<div className='pt-3 px-4'>
							{files1.length && files2.length ? (
								<div
									className='rounded-[5px] text-center py-4'
									style={{
										background: "#CCFFD6",
										boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
									}}
								>
									<p
										className='font-normal text-14 flex justify-center mb-[10px]'
										style={{ color: "#808080" }}
									>
										<Check></Check> Upload Complete.
									</p>
									<p
										className='font-normal text-14'
										style={{ color: "#808080" }}
									>
										Please click "<span style={{ color: "#00B224" }}>NEXT</span>
										" button
									</p>
								</div>
							) : (
								<div
									className='rounded-[5px]'
									style={{
										background: "#FFFFCC",
										boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
									}}
								>
									<p
										className='font-normal p-[14px] text-14'
										style={{ color: "#808080" }}
									>
										Please, upload your{" "}
										<b className='text-primary font-semibold'>“NID”</b> copy.
									</p>
								</div>
							)}

							<div className='pt-20 text-center pb-10'>
								<IonButton
									color='primary'
									className='font-bold h-10'
									onClick={nextHandler}
								>
									{ekkLanguage.jobSelect.btnLabel}
									<span className='pl-2'>
										<ArrayRight />
									</span>
								</IonButton>
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

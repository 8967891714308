import { IonButton, IonCol, IonImg, IonRow } from "@ionic/react";
import { getLanguage } from "config/language";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAllInfo } from "redux/container/userSlice";
import User from "services/User";

function HomePageBanner() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const ekkUser = useSelector((state) => state.user.allInfo);
	const lang = getLanguage();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		let res = User.getBasicInfoUpdate();
		res.then((r) => {
			if (r.status === 200) {
				dispatch(setAllInfo(r.data));
			}
		});
	}, [dispatch]);

	return (
		<div className='pt-20 px-5'>
			<p className='text-center font-medium text-16 leading-6'>
				{ekkLanguage.jobSelect.titlePart1}
				<br />
				{lang === "en" && (
					<span>
						{" "}
						<span>As a</span>
						<span className='font-bold text-[#F94B41]'>
							{" "}
							{ekkLanguage.jobSelect.ekkHero}
						</span>
					</span>
				)}
				{lang === "bn" && (
					<span>
						{" "}
						<span className='font-bold text-[#F94B41]'>
							{ekkLanguage.jobSelect.ekkHero}
						</span>{" "}
						হিসেবে
					</span>
				)}
				{ekkLanguage.jobSelect.titlePart2}

				<span className='font-bold text-[#00B224]'>
					{ekkLanguage.jobSelect.earnMoney}
				</span>
			</p>
			<div className='w-full flex justify-center items-center mt-3'>
				<IonRow>
					<IonCol className={`rounded-md relative `}>
						<IonImg
							className='w-[74px] h-[110px]'
							src='/assets/images/ekkhero/Icons/boy.png'
							alt='boy'
						></IonImg>
					</IonCol>
					<IonCol className={`rounded-md relative `}>
						<IonImg
							className='w-[74px] h-[110px]'
							src='/assets/images/ekkhero/Icons/girl.png'
							alt='girl'
						></IonImg>
					</IonCol>
				</IonRow>
			</div>
			<div className='mt-5 text-center w-full pb-10'>
				{ekkUser?.kyc_info?.status === "Verified" ? (
					<IonButton
						onClick={() => {
							navigate("/registration");
						}}
						color='success'
						className='font-bold h-10 w-full'
					>
						<span>{ekkLanguage.home.updateBtnLabel}</span>
						<img
							className='ml-2'
							src='/assets/images/ekkhero/Icons/update.png'
							alt='update'
						/>
					</IonButton>
				) : (
					<IonButton
						onClick={() => {
							navigate("/registration");
						}}
						color='primary'
						className='font-bold h-10 w-full'
					>
						{ekkLanguage.home.registerBtnLabel}{" "}
					</IonButton>
				)}
			</div>
		</div>
	);
}
// export default withLocation(Home);
export default HomePageBanner;

export const postalCodeInfo = [
	{
		id: 1,
		district: "Dhaka",
		thana: "Rampura",
		postalCode: 1206,
	},
	{
		id: 2,
		district: "Dhaka",
		thana: "Moghbazar",
		postalCode: 1217,
	},
	{
		id: 3,
		district: "Dhaka",
		thana: "Ramna",
		postalCode: 1217,
	},

	{
		id: 4,
		district: "Dhaka",
		thana: "Dhamrai",
		postalCode: 1350,
	},
	{
		id: 5,
		district: "Dhaka",
		thana: "Dhanmondi",
		postalCode: 1209,
	},
	{
		id: 6,
		district: "Dhaka",
		thana: "Gulshan",
		postalCode: 1213,
	},
	{
		id: 7,
		district: "Dhaka",
		thana: "Badda",
		postalCode: 1212,
	},
	{
		id: 8,
		district: "Dhaka",
		thana: "Jatra Bari",
		postalCode: 1232,
	},
	{
		id: 9,
		district: "Dhaka",
		thana: "Dohar",
		postalCode: 1330,
	},
	{
		id: 10,
		district: "Dhaka",
		thana: "Keraniganj",
		postalCode: 1312,
	},
	{ id: 11, district: "Dhaka", thana: "Khilgaon", postalCode: 1219 },
	{ id: 12, district: "Dhaka", thana: "Khilkhet", postalCode: 1229 },
	{ id: 13, district: "Dhaka", thana: "Lalbag", postalCode: 1211 },
	{ id: 14, district: "Dhaka", thana: "Mirpur", postalCode: 1216 },
	{ id: 15, district: "Dhaka", thana: "Mohammadpur", postalCode: 1207 },
	{ id: 16, district: "Dhaka", thana: "Motijheel", postalCode: 1222 },
	{ id: 17, district: "Dhaka", thana: "Nawabganj", postalCode: 1323 },
	{ id: 18, district: "Dhaka", thana: "Dohar", postalCode: 1321 },
	{ id: 19, district: "Dhaka", thana: "Nawabganj", postalCode: 1324 },
	{ id: 20, district: "Dhaka", thana: "Kalabagan", postalCode: 1205 },
	{ id: 21, district: "Dhaka", thana: "Palton", postalCode: 1000 },
	{ id: 22, district: "Dhaka", thana: "Badda", postalCode: 1212 },
	{ id: 23, district: "Dhaka", thana: "Hatirjheel", postalCode: 1217 },
	{ id: 24, district: "Dhaka", thana: "Sabujbag", postalCode: 1214 },
	{ id: 25, district: "Savar", thana: "Amin Bazar", postalCode: 1348 },
	{ id: 26, district: "Savar", thana: "Dairy Farm", postalCode: 1341 },
	{ id: 27, district: "Dhaka", thana: "Sutrapur", postalCode: 1100 },
	{ id: 28, district: "Dhaka", thana: "Tejgaon", postalCode: 1215 },
	{ id: 29, district: "Dhaka", thana: "Uttara", postalCode: 1230 },
	{ id: 30, district: "Dhaka", thana: "Badda", postalCode: 1212 },
	{ id: 31, district: "Narayanganj", thana: "Rupganj", postalCode: 1462 },
	{ id: 32, district: "Tangail", thana: "Tangail Sadar", postalCode: 1900 },
	{ id: 33, district: "Tangail", thana: "Sakhipur", postalCode: 1950 },
];

import {
	IonApp,
	IonContent,
	IonInput,
	IonLabel,
	IonPage,
	IonRow,
	IonCol,
	IonButton,
	IonCheckbox,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Auth from "services/Auth";
import { toast } from "react-toastify";
import { setAllInfo } from "redux/container/userSlice";

export default function DrivingVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [driving, setDriving] = useState(false);
	const [notDriving, setNotDriving] = useState(false);
	const [drivingNumber, setDrivingNumber] = useState("");
	const [vehicle, setVehicle] = useState(false);
	const [notVehicle, setNotVehicle] = useState(false);
	const [haveVehicle, setHaveVehicle] = useState("");
	const [truck, setTruck] = useState(false);
	const [car, setCar] = useState(false);
	const [bike, setBike] = useState(false);
	const [escooter, setEscooter] = useState(false);
	const [cycle, setCycle] = useState(false);
	const [loadAmount, setLoadAmount] = useState("");
	const [vehicleNumber, setVehicleNumber] = useState("");
	const [drivingLicense, setDrivingLicense] = useState("");
	const [error, setError] = useState("");

	useEffect(() => {
		if (driving) {
			setDrivingLicense("Yes");
		} else if (notDriving) {
			setDrivingLicense("No");
		}
		if (vehicle) {
			setHaveVehicle("Yes");
		} else if (notVehicle) {
			setHaveVehicle("No");
		}
	}, [driving, notDriving, vehicle, notVehicle]);

	useEffect(() => {
		if ((driving || notDriving) && (vehicle || notVehicle)) {
			setError("");
		} else if (!driving || !notDriving || !vehicle || !notVehicle) {
			setError(ekkLanguage.addressVerify.errorLabel);
		}
	}, [driving, notDriving, vehicle, notVehicle, ekkLanguage]);

	const handleSubmit = () => {
		console.log(vehicleNumber);
		const drivingData = {
			have_driving_license: drivingLicense,
			driving_license_number: drivingNumber,
			have_vehicle: haveVehicle,
			type_of_vehicle: `${truck ? "truck" : ""} ${car ? "car" : ""} ${
				bike ? "bike" : ""
			} ${escooter ? "escooter" : ""} ${cycle ? "bicycle" : ""}`,
			vehicle_laod_amount: loadAmount,
			vehicle_number: vehicleNumber,
		};
		console.log(drivingData);
		if ((driving || notDriving) && (vehicle || notVehicle)) {
			Auth.setUserInfo(drivingData).then((res) => {
				console.log(res);
				if (res.success) {
					dispatch(setAllInfo(res.data));
					navigate("/home");
					toast.success("Updated driving information!");
				}
			});
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButton>
					<div className='pt-5 px-5'>
						<h6 className='text-18 text-center font-bold text-success mb-[20px]'>
							{ekkLanguage.drivingVerify.drivingTitle}{" "}
						</h6>
						<IonLabel className='flex mb-[11px] font-bold '>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/driving.png'
								alt='work'
							></img>{" "}
							{ekkLanguage.drivingVerify.haveDrivingLabel}{" "}
							<span className='text-primary ml-1'>*</span>
						</IonLabel>
						<IonRow>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 ${
									driving && "border-success"
								}`}
							>
								<IonCheckbox
									checked={driving}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setDriving(!driving);
										setNotDriving(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption1}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white ml-1 ${
									notDriving && "border-success"
								}`}
							>
								<IonCheckbox
									checked={notDriving}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setNotDriving(!notDriving);
										setDriving(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption2}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
						</IonRow>
						<IonLabel className='flex items-center mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/driving-number.png'
								alt='work'
							></img>{" "}
							<span className='mr-1'>
								{ekkLanguage.drivingVerify.licenceNumberLabel}
							</span>
						</IonLabel>
						<IonInput
							value={drivingNumber}
							type='text'
							className='border bg-white pl-[5px] h-[50px] rounded'
							clearInput
							inputMode='text'
							placeholder={ekkLanguage.drivingVerify.licenceNumberPlaceholder}
							color='success'
							onIonChange={(event) => setDrivingNumber(event.target.value)}
						></IonInput>
						<IonLabel className='flex mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/vehicle.png'
								alt='work'
							></img>{" "}
							{ekkLanguage.drivingVerify.haveVehicleLabel}{" "}
							<span className='text-primary ml-1'>*</span>
						</IonLabel>
						<IonRow>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 ${
									vehicle && "border-success"
								}`}
							>
								<IonCheckbox
									checked={vehicle}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setVehicle(!vehicle);
										setNotVehicle(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption1}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white ml-1 ${
									notVehicle && "border-success"
								}`}
							>
								<IonCheckbox
									checked={notVehicle}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setNotVehicle(!notVehicle);
										setVehicle(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption2}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
						</IonRow>

						<IonLabel className='flex items-center font-bold mt-5 mb-[11px]'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/vehicle-type.png'
								alt='work'
							></img>{" "}
							<span>{ekkLanguage.drivingVerify.vehicleTypeLabel}</span>
						</IonLabel>

						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
								!truck && "checkbox-overlay"
							} ${truck && "border-2 border-success "}`}
						>
							<IonCheckbox
								checked={truck}
								className='pl-[16px] font-bold custom-checkbox'
								color='success'
								onIonChange={() => setTruck(!truck)}
								labelPlacement='end'
							>
								<span className='flex'>
									<img
										className='ml-2 mr-[10px] w-5'
										src='/assets/images/ekkhero/Icons/driving-verify/truck.png'
										alt=''
									/>
									<p className='text-14'>{ekkLanguage.drivingVerify.truck}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
								!car && "checkbox-overlay"
							} ${car && "border-2 border-success "}`}
						>
							<IonCheckbox
								checked={car}
								className='pl-[16px] font-bold custom-checkbox'
								color='success'
								onIonChange={() => setCar(!car)}
								labelPlacement='end'
							>
								<span className='flex'>
									<img
										className='ml-2 mr-[10px] w-5'
										src='/assets/images/ekkhero/Icons/driving-verify/car.png'
										alt=''
									/>
									<p className='text-14'>{ekkLanguage.drivingVerify.car}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
								!bike && "checkbox-overlay"
							} ${bike && "border-2 border-success "}`}
						>
							<IonCheckbox
								checked={bike}
								className='pl-[16px] font-bold custom-checkbox'
								color='success'
								onIonChange={() => setBike(!bike)}
								labelPlacement='end'
							>
								<span className='flex'>
									<img
										className='ml-2 mr-[10px] w-5'
										src='/assets/images/ekkhero/Icons/driving-verify/motorcycle.png'
										alt=''
									/>
									<p className='text-14'>
										{ekkLanguage.drivingVerify.motorcycle}
									</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
								!escooter && "checkbox-overlay"
							} ${escooter && "border-2 border-success "}`}
						>
							<IonCheckbox
								checked={escooter}
								className='pl-[16px] font-bold custom-checkbox'
								color='success'
								onIonChange={() => setEscooter(!escooter)}
								labelPlacement='end'
							>
								<span className='flex'>
									<img
										className='ml-2 mr-[10px] w-5'
										src='/assets/images/ekkhero/Icons/driving-verify/escooter.png'
										alt=''
									/>
									<p className='text-14'>
										{ekkLanguage.drivingVerify.escooter}
									</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
								!cycle && "checkbox-overlay"
							} ${cycle && "border-2 border-success "}`}
						>
							<IonCheckbox
								value={cycle}
								className='pl-[16px] font-bold custom-checkbox'
								color='success'
								onIonChange={() => setCycle(!cycle)}
								labelPlacement='end'
							>
								<span className='flex'>
									<img
										className='ml-2 mr-[10px] w-5'
										src='/assets/images/ekkhero/Icons/driving-verify/bicycle.png'
										alt=''
									/>
									<p className='text-14'>{ekkLanguage.drivingVerify.bicycle}</p>
								</span>
							</IonCheckbox>
						</div>
						<IonLabel className='flex items-center mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/load.png'
								alt='work'
							></img>{" "}
							<span className='mr-1'>
								{ekkLanguage.drivingVerify.loadAmountLabel}
								{ekkLanguage.drivingVerify.loadAmountLabel2}
							</span>
						</IonLabel>
						<IonInput
							value={loadAmount}
							type='number'
							className='border bg-white pl-[5px] h-[50px] rounded'
							clearInput
							inputMode='text'
							placeholder={ekkLanguage.drivingVerify.loadAmountPlaceholder}
							color='success'
							onIonChange={(event) => setLoadAmount(event.target.value)}
						></IonInput>
						<IonLabel className='flex items-center mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/driving-verify/vehicle-number.png'
								alt='work'
							></img>{" "}
							<span className='mr-1'>
								{ekkLanguage.drivingVerify.vehicleNumberLabel}
							</span>
						</IonLabel>
						<IonInput
							value={vehicleNumber}
							type='text'
							className='border bg-white pl-[5px] h-[50px] rounded'
							clearInput
							inputMode='text'
							placeholder={ekkLanguage.drivingVerify.vehicleNumberPlaceholder}
							color='success'
							onIonBlur={(event) => {
								setVehicleNumber(event.target.value);
								console.log(event.target.value);
							}}
						></IonInput>
						{error && (
							<p className='font-thin text-12 text-primary mt-5'>{error}</p>
						)}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-10'
						>
							{ekkLanguage.jobSelect.btnLabel}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						<IonButton
							onClick={() => {
								navigate("/home");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 mt-5 w-full  border-primary'
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import Star from "assets/images/icon/svg/Star";
import DropzoneInput from "components/global-components/common/DropzoneInput";
import { getLocalUser } from "config/user";
import { digitChanger } from "helpers/helper";
import React, { useState } from "react";

export default function IdentityCard({ user }) {
	const { user: userData } = getLocalUser();
	let [profilePic, setProfilePic] = useState(userData?.profile_image);
	return (
		<div className='px-5 mb-[10px]'>
			<div className='text-center bg-white rounded-[30px] rounded-t-none pb-6'>
				<div className='pt-7 pb-4 flex items-center justify-center'>
					<div className='relative  rounded-full'>
						{profilePic ? (
							<img
								src={profilePic}
								alt='avatar'
								className='m-auto h-24 w-24 rounded-full'
							/>
						) : (
							<img
								src='assets/images/avatar.png'
								alt='avatar'
								className='m-auto h-24 w-24 rounded-full'
							/>
						)}
						<DropzoneInput setProfilePic={setProfilePic} />
					</div>
				</div>

				<div className='flex justify-center pb-2'>
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#00000080' />
					<p className='font-medium text-12 text-[#00000080] ml-[5px]'>4.0</p>
				</div>
				<h6 className='text-20 text-222222 font-semibold mb-2'>
					{userData?.DisplayName}
				</h6>
				<p className='text-12 font-semibold text-black-500'>
					{digitChanger(userData?.PhoneNumber)}
				</p>
			</div>
		</div>
	);
}

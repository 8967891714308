import React, { useRef, useState } from "react";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { getLanguage, setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { selectors } from "redux/container/authSlice";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
export default function Password({
	password,
	setPassword,
	loading,
	startLoading,
	setBlockIndex,
	setIsForgotPassword,
	stopLoading,
	phoneNo,
	country,
	countryCode,
	sSize,
}) {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { loginStatus } = useSelector(selectors.getAuth);
	const [errorMessage, setErrorMessage] = useState("");

	const forgotPass = () => {
		return (
			<span
				onClick={() => onForgotPassword(phoneNo, country, countryCode)}
				className='text-xs underline text-red'>
				{ekkLanguage?.password.forgetPass}
			</span>
		);
	};
	const isValidate = ({ password }) => {
		if (password.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.current.click();
		}
	};

	//forgotpassword handler
	const onForgotPassword = async (phoneNumber, country, countryCode) => {
		navigate("/login");
		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
		};
		try {
			startLoading();

			let response = await Auth.resendOptToken(req_body);
			if (response.status === 200 || response.status === 201) {
				await setIsForgotPassword(true);
				setBlockIndex(authBox_Block.TOKEN);
				toast.success(ekkLanguage?.password.otpSendLabel);
			}
			stopLoading();
		} catch (e) {
			stopLoading();
			console.log(e);
		}
	};

	//this is login handler for calling redux login dispatch.
	const loginHandler = async (countryCode, country, phoneNo, password) => {
		setErrorMessage("");
		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			phone_number: (countryCode + phoneNo).trim(),
			country,
			password,
		};
		try {
			const response = await Auth.login(req_body);
			if (response.success) {
				dispatch(loginFromSignUp(response));
				updateToken(response);
				setLocalUser(response?.data);
				if (response?.data?.user.Country === "Bangladesh") {
					// navigate("/home");
					setLanguage("en");
					dispatch(selectLan());
				} else {
					setLanguage("en");
					dispatch(selectLan());
				}
			}
		} catch (e) {
			stopLoading();
			console.log(e);
			setErrorMessage(ekkLanguage?.password.passError);
			// toast.error(e.errorData.message);
		}
	};

	return (
		<div>
			<div>
				<label
					className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
					{ekkLanguage?.password.passwordLabel}
					<span
						onClick={() =>
							setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK)
						}
						className='text-xs text-red cursor-pointer flex items-center'>
						<span className='mr-1'>
							<LeftArrow2 />
						</span>
						{ekkLanguage?.password.backWord}
					</span>
				</label>
				<input
					type='password'
					onKeyPress={_handleKeyPress}
					onChange={(event) => setPassword(event.target.value)}
					placeholder={ekkLanguage?.password.passwordPlaceHolder}
					id={"ekk-input"}
					style={{
						paddingLeft: 30,
						height: sSize.matches ? 50 : 80,
						flex: "flex: 1 0 auto",
						borderRadius: ".25rem",
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						borderWidth: 0,
						borderLeft: "1px solid rgba(0,0,0,.05)",
					}}
					className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
				/>
				<div className='pt-4'>
					<p className='text-red text-xs text-primary'>{errorMessage}</p>
				</div>
				<div>{forgotPass()}</div>
			</div>

			<div className='py-4'>
				<button
					onClick={() =>
						loginHandler(countryCode, country, phoneNo, password)
					}
					ref={clickBtnRef}
					style={{
						background:
							"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
					}}
					className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
					{ekkLanguage?.password.btnLabel}
				</button>
				<div className='text-left pt-4'>
					<p className='font-bold text-14'>
						{ekkLanguage?.login?.langChange}
						<span
							className={`${
								getLanguage() == "en"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								getLanguage() == "bn"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}

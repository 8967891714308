import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useRef, useState } from "react";
import HomePageBanner from "./HomePage/HomePageBanner";
import RegisterProcess from "./HomePage/RegisterProcess";
import Recommendation from "./HomePage/Recommendation";
import { useSelector } from "react-redux";

function Home() {
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}
					// onIonScrollEnd={(ev) => {
					// 	if (productCount > products.length) {
					// 		searchNext(ev);
					// 	}
					// }}
				>
					<BackButtonTitle
						title={ekkLanguage.letNav.homeMenuLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<HomePageBanner></HomePageBanner>
					<RegisterProcess></RegisterProcess>
					<Recommendation></Recommendation>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
// export default withLocation(Home);
export default Home;

import Pen from "assets/images/icon/svg/Pen";
import { getLocalUser, getUser } from "config/user";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import User from "services/User";
import { setUser } from "redux/container/userSlice";
import { useDispatch } from "react-redux";
import { setLocalUser } from "config/user";

const DropzoneInput = ({ name = "image", setProfilePic }) => {
	const [files, setFiles] = useState([]);
	const [value, setValue] = useState(null);
	const user = getLocalUser();
	let dispatch = useDispatch();

	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);
	let formData = new FormData();
	useEffect(() => {
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = User.toBucketImage(formData);
			response.then((res) => {
				if (res.status === 201) {
					var reqbody = {
						profile_image: res.results[0],
					};
					let resp = User.basicInfoUpdate(user.user._id, reqbody);

					resp.then((r) => {
						if (r.status === 200) {
							let res = User.getBasicInfoUpdate();
							res.then((r) => {
								if (r.status === 200) {
									setProfilePic(r.data.profile_image);
									let userData = {
										...user.user,
										profile_image: r.data.profile_image,
									};
									let data = { ...user, user: userData };

									setLocalUser(data);
								}
							});
						}
					});
				}
				setValue(name, res.data[0]);
			});
		}
	}, [files]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});
	return (
		<section>
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<span
					className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-1 bottom-0 border-4 border-white'
					style={{ background: "#5A7EFF", lineHeight: "28px" }}>
					<Pen />
				</span>
			</div>
		</section>
	);
};

export default DropzoneInput;

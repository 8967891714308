import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectors } from "redux/container/authSlice";
import BannerArea from "./BannerArea";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import Blog from "./Blog";
import Partners from "./Partners";
import Backers from "./Backers";
import OurLocation from "./OurLocation";
import OurTeam from "./OurTeam";
import CustomerImpact from "./CustomerImpact";
import BotFeatures from "./BotFeatures";

export default function Landing() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const { loginStatus } = useSelector(selectors.getAuth);
	const navigate = useNavigate();
	// useEffect(() => {
	// 	function onScroll() {
	// 		// let currentPosition = window.pageYOffset;
	// 		// document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
	// 		let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
	// 		if (currentPosition > 150) {
	// 			setScrolling(true);
	// 		} else {
	// 			setScrolling(false);
	// 		}
	// 		setScrollTop(currentPosition);
	// 	}

	// 	window.addEventListener("scroll", onScroll);
	// 	return () => window.removeEventListener("scroll", onScroll);
	// }, [scrollTop]);

	useEffect(() => {
		// navigate(loginStatus ? "/home" : "/");
	}, [loginStatus]);

	if (localStorage.getItem("lan") === null) {
		setLanguage();
	}
	// const { pathname } = useLocation();

	// useEffect(() => {
	// 	console.log(pathname);
	// 	window.scrollTo(0, 0);
	// }, [pathname]);

	return (
		<section style={{ background: "#f2f3f7" }}>
			<Navigation scrolling={scrolling ? "isScrolled" : ""} />
			<BannerArea />
			{/* <CustomerImpact /> */}
			<OurTeam />
			{/* <Blog /> */}
			{/* <OurLocation /> */}
			{/* <BotFeatures /> */}
			{/* <Backers /> */}
			<Partners />
			<Footer />
		</section>
	);
}

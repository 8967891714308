import React from "react";
import Slider from "react-slick";
import "./style.scss";

export default function BannerSlider() {
	const settings = {
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		dotsClass: "slick-dots custom-slick-dots",
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		appendDots: (dots) => <ul>{dots}</ul>,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
				},
			},
		],
	};

	return (
		<div>
			<Slider {...settings}>
				<div>
					<img
						src='assets/images/bots/bot1.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div>
				<div>
					<img
						src='assets/images/bots/bot2.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div>
				<div>
					<img
						src='assets/images/bots/bot3.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div>
				<div>
					<img
						src='assets/images/bots/bot4.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div>
				{/* <div>
					<img
						src='assets/images/bots/bot5.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div> */}
				{/* <div>
					<img
						src='assets/images/bots/bot6.png'
						alt='banner'
						className='h-[350px]'
					/>
				</div> */}

				{/* <div>
          <img src="assets/images/l_banner3.png" alt="banner" />
        </div> */}
			</Slider>
		</div>
	);
}

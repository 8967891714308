import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getLanguage } from "config/language";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

function Analytics() {
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const lang = getLanguage();
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}
				>
					<BackButtonTitle
						title={ekkLanguage.letNav.analyticsMenuLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<div className='mt-20 px-5'>
						<h1 className='text-center font-bold text-20'>
							Analytics is Coming Soon...
						</h1>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default Analytics;

import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButton from "components/mobile-components/Common/BackButton";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getLanguage } from "config/language";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function JobDetails() {
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const lang = getLanguage();
	const navigate = useNavigate();

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.home.jobDetailsTitle}
						sticky={true}
					></BackButton>
					<div className='py-5 px-4'>
						<div className='flex items-center mb-[10px]'>
							<img
								className='w-[50px] h-[50px] p-2  bg-white rounded-lg'
								src='/assets/images/ekkhero/Icons/ekkbaz-logo.png'
								alt='ekkbaz'
							/>
							<p className='font-bold text-16 ml-[10px]'>
								Paid Internship Opportunity at EkkBaz!
							</p>
						</div>
						<img
							className='rounded-lg w-full h-full'
							src='/assets/images/ekkhero/job-poster2.png'
							alt='job-poster'
						/>
						<div className='mt-[20px] '>
							<p className='text-justify  mt-[10px] leading-5'>
								Paid internship opportunity at EkkBaz (for Freshers). We are
								actively hiring! EkkBaz Bangladesh is offering a truly ambitious
								career opportunity for those who are driven to make a real
								impact in the world. Paid internship opportunity at EkkBaz (for
								Freshers). We are actively hiring! EkkBaz Bangladesh is offering
								a truly ambitious career opportunity for those who are driven to
								make a real impact in the world.
							</p>
							<p className='font-semibold mt-[10px]'>
								{ekkLanguage.home.clickInfoLabel}
							</p>
							<div className='flex justify-between mt-5'>
								<p className='font-bold text-primary'>End Date: 28 May 2023</p>
								<p className='font-bold text-primary'>20 Candidates</p>
							</div>
							<IonButton
								onClick={() => {
									navigate("/home");
								}}
								color='success'
								className='font-bold h-10 w-full mt-5'
							>
								{ekkLanguage.home.applyNowBtnLabel}
								<span className='pl-2'>
									<img
										src='/assets/images/ekkhero/Icons/apply.svg'
										alt='apply'
									/>
								</span>
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default JobDetails;

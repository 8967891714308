import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import BlogData from "services/Blog";
import SingleBlog from "./SingleBlog";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function BlogPostPage() {
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);
	const [blogList, setBlogList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { pathname } = useLocation();
	// useEffect(() => {
	// 	function onScroll() {
	// 		// let currentPosition = window.pageYOffset;
	// 		// document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
	// 		let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
	// 		if (currentPosition > 150) {
	// 			setScrolling(true);
	// 		} else {
	// 			setScrolling(false);
	// 		}
	// 		setScrollTop(currentPosition);
	// 	}

	// 	window.addEventListener("scroll", onScroll);
	// 	return () => window.removeEventListener("scroll", onScroll);
	// }, [scrollTop]);

	const getListBlog = async () => {
		setLoading(true);
		let response = await BlogData.getBlogList(page);
		if (response.status === 200) {
			setLoading(false);
			setPage(page + 1);
			setBlogList([...blogList, ...response.results]);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getListBlog();
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, []);

	return (
		<section>
			<Navigation
				// scrolling={scrolling ? "isScrolled" : ""}
				bg={true}
				blogPost={true}
			/>
			{!loading ? (
				<div className='pt-[100px] sm:pt-[120px] pb-24'>
					<div className='container'>
						<div className='flex flex-wrap'>
							{Array.isArray(blogList) &&
								blogList?.map((el, i) => {
									return (
										<div className='md:w-1/2 lg:w-1/3 px-4 w-full'>
											<SingleBlog item={el} key={i} />
										</div>
									);
								})}
						</div>
						<div className='text-center'>
							<button
								onClick={() => getListBlog(page)}
								className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}>
								{ekkLanguage.landing.seeMore}
							</button>
						</div>
					</div>
				</div>
			) : (
				<div className='pt-[100px] sm:pt-[150px] pb-24'>
					<div className='m-auto w-full lg:w-8/12'>
						<div className='flex h-full justify-center items-center'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Footer />
		</section>
	);
}

import {
	IonApp,
	IonContent,
	IonInput,
	IonLabel,
	IonPage,
	IonRow,
	IonCol,
	IonButton,
	IonCheckbox,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo, setUser } from "redux/container/userSlice";
import Select from "react-select";
import { postalCodeInfo } from "data/postalCode";
import ImageUploader from "components/mobile-components/VerifyBusiness/ImageUploader";
import User from "services/User";
import Auth from "services/Auth";
import { toast } from "react-toastify";

export default function WorkVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [error, setError] = useState("");

	const [partTime, setPartTime] = useState(false);
	const [fullTime, setFullTime] = useState(false);
	const [previousEx, setPreviousEx] = useState(false);
	const [notPreviousEx, setNotPreviousEx] = useState(false);
	const [expectedSalary, setExpectedSalary] = useState("");
	const [expectedWorkArea, setExpectedWorkArea] = useState("");
	const [education, setEducation] = useState("");
	const [cv, setCv] = useState("");
	const [psc, setPsc] = useState(false);
	const [jsc, setJsc] = useState(false);
	const [ssc, setSsc] = useState(false);
	const [hsc, setHsc] = useState(false);
	const [others, setOthers] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [files2, setFiles2] = useState("");

	useEffect(() => {
		if (
			(partTime || fullTime) &&
			(previousEx || notPreviousEx) &&
			expectedWorkArea
		) {
			setError("");
		} else if (
			!partTime ||
			!fullTime ||
			!previousEx ||
			!notPreviousEx ||
			!expectedWorkArea
		) {
			setError(ekkLanguage.addressVerify.errorLabel);
		}
	}, [
		partTime,
		fullTime,
		previousEx,
		notPreviousEx,
		expectedWorkArea,
		ekkLanguage,
	]);

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		setExpectedWorkArea(event.target.value);
		console.log(selectedOption);
	};

	const handleSubmit = async () => {
		let CV = new FormData();
		CV.append("key", files2[0]);
		if (files2.length !== 0) {
			let response = await User.toBucketImage(CV);
			if (response.status === 201) {
				setCv(response.results[0]);
				const workData = {
					degree: education,
					cv: response.results[0],
				};
				console.log(workData);
				if (
					(partTime || fullTime) &&
					(previousEx || notPreviousEx) &&
					expectedWorkArea
				) {
					setError("");
					Auth.setUserInfo(workData).then((res) => {
						console.log(res);
						if (res.success) {
							dispatch(setAllInfo(res.data));
							toast.success("Work & Salary saved Successfully!");
							navigate("/skill-verify");
						}
					});
				}
			}
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButton>
					<div className='px-4 py-5'>
						<h6 className='text-18 text-center font-bold text-success mb-[20px]'>
							{ekkLanguage.workVerify.workVerifyTitle}{" "}
						</h6>
						<IonLabel className='flex mb-[11px] font-bold '>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/work-type.png'
								alt='work'
							></img>{" "}
							{ekkLanguage.workVerify.workTypeLabel}{" "}
							<span className='text-primary ml-1'>*</span>
						</IonLabel>
						<IonRow>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 ${
									partTime && "border-success"
								}`}
							>
								<IonCheckbox
									checked={partTime}
									className='pl-[4px] font-bold'
									color='success'
									onIonChange={() => {
										setPartTime(!partTime);
										setFullTime(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='mr-2'
											src='/assets/images/ekkhero/Icons/time 1.png'
											alt=''
										/>
										<p>{ekkLanguage.workVerify.workTypeOption1}</p>
									</span>
								</IonCheckbox>
							</IonCol>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white ml-1 ${
									fullTime && "border-success"
								}`}
							>
								<IonCheckbox
									checked={fullTime}
									className='pl-[4px] font-bold'
									color='success'
									onIonChange={() => {
										setFullTime(!fullTime);
										setPartTime(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='mr-2'
											src='/assets/images/ekkhero/Icons/full-time 1.png'
											alt=''
										/>
										<p>{ekkLanguage.workVerify.workTypeOption2}</p>
									</span>
								</IonCheckbox>
							</IonCol>
						</IonRow>
						<IonLabel className='flex mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/experience.png'
								alt='work'
							></img>{" "}
							{ekkLanguage.workVerify.previousWorkExperienceLabel}{" "}
							<span className='text-primary ml-1'>*</span>
						</IonLabel>
						<IonRow>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 ${
									previousEx && "border-success"
								}`}
							>
								<IonCheckbox
									checked={previousEx}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setPreviousEx(!previousEx);
										setNotPreviousEx(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption1}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
							<IonCol
								className={`w-full h-[50px] border rounded flex items-center bg-white ml-1 ${
									notPreviousEx && "border-success"
								}`}
							>
								<IonCheckbox
									checked={notPreviousEx}
									className='pl-[5px] font-bold'
									color='success'
									onIonChange={() => {
										setNotPreviousEx(!notPreviousEx);
										setPreviousEx(false);
									}}
									labelPlacement='end'
								>
									<span className='flex'>
										<p>
											{ekkLanguage.workVerify.previousWorkExperienceOption2}
										</p>
									</span>
								</IonCheckbox>
							</IonCol>
						</IonRow>
						<IonLabel className='flex items-center mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/salary.png'
								alt='work'
							></img>{" "}
							<span className='mr-1'>
								{ekkLanguage.workVerify.monthlySalaryLabel}
							</span>
							<p className='font-extralight'>
								({ekkLanguage.workVerify.monthlySalaryLabel2})
							</p>
						</IonLabel>
						<IonInput
							value={expectedSalary}
							type='number'
							className='border bg-white pl-[5px] h-[50px] rounded'
							clearInput
							inputMode='text'
							placeholder={ekkLanguage.workVerify.monthlySalaryPlaceholder}
							color='success'
							onIonChange={(event) => setExpectedSalary(event.target.value)}
						></IonInput>
						<IonLabel className='flex items-center mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/location-pin1.png'
								alt='work'
							></img>{" "}
							<span>{ekkLanguage.workVerify.workAreaLabel}</span>
							<span className='text-primary mx-1'>*</span>
							<p className='font-extralight'>
								({ekkLanguage.workVerify.workAreaLabel2})
							</p>
						</IonLabel>

						<select
							className='w-full h-[50px] bg-white rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:w-full'
							style={{
								paddingRight: "20px", // Adjust the value as needed
								backgroundImage:
									'url("/assets/images/ekkhero/Icons/dropdown.png")', // Replace with your desired dropdown icon path
								backgroundRepeat: "no-repeat",
								backgroundPosition: "right 4px center",
							}}
						>
							<option value=''>
								{ekkLanguage.workVerify.workAreaPlaceholder}
							</option>
							{postalCodeInfo.map((item) => (
								<option key={item.id} value={item.thana}>
									{item.thana}
								</option>
							))}
						</select>

						<IonLabel className='flex items-center font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/education.png'
								alt='work'
							></img>{" "}
							<span>{ekkLanguage.workVerify.academicQualificationLabel}</span>
						</IonLabel>
						<IonLabel className='flex items-center mb-[11px] ml-6 font-bold'>
							<p className='font-extralight'>
								({ekkLanguage.workVerify.academicQualificationLabel2})
							</p>
						</IonLabel>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 mb-[10px] ${
								psc && "border-success "
							}`}
						>
							<IonCheckbox
								checked={psc}
								className='pl-[5px] font-bold'
								color='success'
								onIonChange={() => {
									setPsc(!psc);
									setJsc(false);
									setSsc(false);
									setHsc(false);
									setOthers(false);
									setEducation("PSC");
								}}
								labelPlacement='end'
							>
								<span className='flex'>
									<p>{ekkLanguage.workVerify.academiOption1}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 mb-[10px] ${
								jsc && "border-success"
							}`}
						>
							<IonCheckbox
								checked={jsc}
								className='pl-[5px] font-bold'
								color='success'
								onIonChange={() => {
									setJsc(!jsc);
									setSsc(false);
									setHsc(false);
									setOthers(false);
									setPsc(false);
									setEducation("JSC");
								}}
								labelPlacement='end'
							>
								<span className='flex'>
									<p>{ekkLanguage.workVerify.academiOption2}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 mb-[10px] ${
								ssc && "border-success"
							}`}
						>
							<IonCheckbox
								checked={ssc}
								className='pl-[5px] font-bold'
								color='success'
								onIonChange={() => {
									setSsc(!ssc);
									setHsc(false);
									setOthers(false);
									setPsc(false);
									setJsc(false);
									setEducation("SSC");
								}}
								labelPlacement='end'
							>
								<span className='flex'>
									<p>{ekkLanguage.workVerify.academiOption3}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 mb-[10px] ${
								hsc && "border-success"
							}`}
						>
							<IonCheckbox
								checked={hsc}
								className='pl-[5px] font-bold'
								color='success'
								onIonChange={() => {
									setHsc(!hsc);
									setOthers(false);
									setPsc(false);
									setJsc(false);
									setSsc(false);
									setEducation("HSC");
								}}
								labelPlacement='end'
							>
								<span className='flex'>
									<p>{ekkLanguage.workVerify.academiOption4}</p>
								</span>
							</IonCheckbox>
						</div>
						<div
							className={`w-full h-[50px] border rounded flex items-center bg-white mr-1 ${
								others && "border-success"
							}`}
						>
							<IonCheckbox
								checked={others}
								className='pl-[5px] font-bold'
								color='success'
								onIonChange={() => {
									setOthers(!others);
									setPsc(false);
									setJsc(false);
									setSsc(false);
									setHsc(false);
									setEducation("Others");
								}}
								labelPlacement='end'
							>
								<span className='flex'>
									<p>{ekkLanguage.workVerify.academiOption5}</p>
								</span>
							</IonCheckbox>
						</div>
						<IonLabel className='flex items-center font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/file.png'
								alt='work'
							></img>{" "}
							<span>{ekkLanguage.workVerify.addFileLabel}</span>
						</IonLabel>
						<IonLabel className='flex items-center mb-[11px] ml-6 font-bold'>
							<p className='font-extralight'>
								({ekkLanguage.workVerify.addFileLabel2})
							</p>
						</IonLabel>
						<div className='pb-5 px-4'>
							<ImageUploader
								files={files2}
								setFiles={setFiles2}
								bHeight={200}
								placeHolder2={ekkLanguage.workVerify.addFilePlaceholder}
							/>
						</div>
						{error && (
							<p className='font-thin text-12 text-primary mt-5'>{error}</p>
						)}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-5'
						>
							{ekkLanguage.jobSelect.btnLabel}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						<IonButton
							onClick={() => {
								navigate("/skill-verify");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 mt-5 w-full border-primary '
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "pages/Auth/Login";
import Home from "pages/MobilePage/Home";
import Map from "pages/MobilePage/Map";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ContactPage from "pages/MobilePage/ContactPage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import LocationPage from "pages/MobilePage/LocationPage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import Error from "pages/MobilePage/Error";
import Landing from "pages/Landing";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import TermsCondition from "pages/MobilePage/TermsCondition";
import PickLocationPage from "pages/MobilePage/PickLocationPage";
import PrivateOutlet from "./PrivateOutlet";
import JobSelect from "components/pages-components/LogIn/JobSelect";
import Nid from "components/mobile-components/VerifyBusiness/Nid";
import TaskList from "pages/MobilePage/TaskList";
import Analytics from "pages/MobilePage/Analytics";
import AddressVerify from "pages/MobilePage/AddressVerify";
import WorkVerify from "pages/MobilePage/WorkVerify";
import SkillVerify from "pages/MobilePage/SkillVerify";
import DrivingVerify from "pages/MobilePage/DrivingVerify";
import Refer from "pages/MobilePage/Refer";
import JobDetails from "pages/MobilePage/JobDetails";
import ReferList from "pages/MobilePage/ReferList";
import BlogPostPage from "pages/Landing/BlogPostPage";
import SingleBlogPage from "pages/Landing/SingleBlogPage";

function Mobile() {
	const pathname = useLocation();
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<Routes>
			<Route path='/' element={<Landing />} />
			<Route path='/post/en' element={<BlogPostPage />} />
			<Route path='/post/en/:id' element={<SingleBlogPage />} />
			<Route path='/login' element={<Login />} />
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
		</Routes>
	);
}

export default Mobile;

import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import IdentityCard from "components/mobile-components/Profile/IdentityCard";
import SettingsCard from "components/mobile-components/Profile/SettingsCard";
import { getUser } from "config/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "redux/container/userSlice";
import User from "services/User";

export default function ProfilePage() {
	let user = getUser();
	let dispatch = useDispatch();

	const getUserInfo = async () => {
		let response = await User.getBasicInfoUpdate();
		if (response.status === 200) {
			dispatch(setUser(response.data));
		}
	};
	useEffect(() => {
		getUserInfo();
	});
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.profile.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<section className='pt-14'>
						<IdentityCard user={user} />
						<SettingsCard />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import {
	IonApp,
	IonContent,
	IonInput,
	IonLabel,
	IonPage,
	IonRow,
	IonCol,
	IonButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import { getLanguage } from "config/language";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo } from "redux/container/userSlice";
import Auth from "services/Auth";
import { toast } from "react-toastify";

export default function AddressVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const ekkUser = useSelector((state) => state.user.allInfo);
	const [error, setError] = useState("");
	const lang = getLanguage();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [presentAddress, setPresentAddress] = useState(
		ekkUser?.contact_and_basic_info?.address?.address_line
	);
	const [city, setCity] = useState(
		ekkUser?.contact_and_basic_info?.address?.city
	);
	const [postalCode, setPostalCode] = useState(
		ekkUser?.contact_and_basic_info?.address?.postal_code
	);
	const [gaurdianName, setGaurdianName] = useState("");
	const [gaurdianNumber, setGaurdianNumber] = useState("");

	useEffect(() => {
		if (presentAddress && city && gaurdianNumber) {
			setError("");
		} else if (!presentAddress || !city || !gaurdianNumber) {
			setError(ekkLanguage.addressVerify.errorLabel);
		}
	}, [
		presentAddress,
		city,
		postalCode,
		gaurdianName,
		gaurdianNumber,
		ekkLanguage,
	]);

	const handleSubmit = () => {
		if (presentAddress && city && gaurdianNumber) {
			Auth.setUserInfo({
				address: presentAddress,
				city: city,
				postal_code: postalCode,
			}).then((res) => {
				console.log(res);
				if (res.success) {
					dispatch(setAllInfo(res.data));
					toast.success("Address saved successfully!");
					navigate("/work-verify");
				}
			});
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButton>
					<div className='pt-5 px-5'>
						<h6 className='text-18 text-center mb-[30px]'>
							{ekkLanguage.addressVerify.addressTitlePart1}{" "}
							<span className='font-bold text-success'>
								{ekkLanguage.addressVerify.addressTitlePart2}
							</span>
							{lang === "bn" && " দিন"}
						</h6>
						<IonLabel className='flex mb-[11px] font-bold '>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/location-pin1.png'
								alt='location'
							></img>{" "}
							{ekkLanguage.addressVerify.presentAddressLabel}{" "}
							<span className='text-primary ml-1'>*</span>
						</IonLabel>
						<IonInput
							value={presentAddress}
							autofocus
							required
							clearInput
							type='addressline1'
							className='border bg-white pl-[5px] h-[50px] rounded'
							placeholder={ekkLanguage.addressVerify.presentAddressPlaceholder}
							color='success'
							onIonChange={(event) => {
								setPresentAddress(event.target.value);
								console.log(event.target.value);
							}}
						></IonInput>
						<IonRow className='mt-5'>
							<IonCol>
								<IonLabel className='flex mb-[11px] font-bold '>
									<img
										className='mr-[10px]'
										src='/assets/images/ekkhero/Icons/present-address.png'
										alt='location'
									></img>{" "}
									{ekkLanguage.addressVerify.presentCityLabel}{" "}
									<span className='text-primary ml-1'>*</span>
								</IonLabel>
								<IonInput
									value={city}
									type='address-line2'
									required
									clearInput
									className='border bg-white pl-[5px] h-[50px] rounded'
									placeholder={ekkLanguage.addressVerify.presentCityPlaceholder}
									color='success'
									onIonChange={(event) => setCity(event.target.value)}
								></IonInput>
							</IonCol>
							<IonCol>
								<IonLabel className='flex mb-[11px] font-bold '>
									<img
										className='mr-[10px]'
										src='/assets/images/ekkhero/Icons/postal-code.png'
										alt='location'
									></img>{" "}
									{ekkLanguage.addressVerify.postalCodeLabel}
								</IonLabel>
								<IonInput
									value={postalCode}
									type='address-line1'
									clearInput
									className='border bg-white pl-[5px] h-[50px] rounded'
									placeholder={ekkLanguage.addressVerify.postalCodePlaceholder}
									color='success'
									onIonChange={(event) => setPostalCode(event.target.value)}
								></IonInput>
							</IonCol>
						</IonRow>
						<IonLabel className='flex mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/user.png'
								alt='location'
							></img>{" "}
							{ekkLanguage.addressVerify.nameLabel}{" "}
							<span className='ml-1 font-thin text-gray-600'>
								({ekkLanguage.addressVerify.extraLabel})
							</span>
						</IonLabel>
						<IonInput
							value={gaurdianName}
							type='name'
							className='border bg-white pl-[5px] h-[50px] rounded'
							clearInput
							inputMode='text'
							placeholder={ekkLanguage.addressVerify.namePlaceholder}
							color='success'
							onIonChange={(event) => setGaurdianName(event.target.value)}
						></IonInput>
						<IonLabel className='flex mb-[11px] font-bold mt-5'>
							<img
								className='mr-[10px]'
								src='/assets/images/ekkhero/Icons/phone.png'
								alt='location'
							></img>{" "}
							{ekkLanguage.addressVerify.numberLabel}{" "}
							<span className='text-primary ml-1'>*</span>
							<span className='ml-1 font-thin text-gray-600'>
								({ekkLanguage.addressVerify.extraLabel})
							</span>
						</IonLabel>
						<IonInput
							type='tel-local'
							value={gaurdianNumber}
							required
							clearInput
							inputMode='tel'
							className='border bg-white pl-[5px] h-[50px] rounded'
							placeholder={ekkLanguage.addressVerify.numberPlaceholder}
							color='success'
							onIonChange={(event) => setGaurdianNumber(event.target.value)}
						></IonInput>
						{error && (
							<p className='font-thin text-12 text-primary mt-5'>{error}</p>
						)}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-20'
						>
							{ekkLanguage.jobSelect.btnLabel}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						<IonButton
							onClick={() => {
								navigate("/work-verify");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 mt-5 w-full  border-primary '
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

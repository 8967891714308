import React from "react";
import "./style.scss";

export default function SectionHeader({ title }) {
	return (
		<div className='pb-[50px] md:pb-[50px] lg:pb-[70px] text-center relative'>
			<h2
				className='text-26 sm:text-36 md:text-48 text-262626-1000 z-10 font-extrabold relative'
				style={{ zIndex: "10" }}>
				{title}
			</h2>
			<img
				src='assets/images/line.png'
				alt='line'
				className='absolute left-[50%] top-[12px] sm:top-[12px] md:top-[37px] w-[100px] sm:w-[100px] md:w-[auto]'
				style={{ transform: "translate(-50%)", zIndex: "1" }}
			/>
		</div>
	);
}

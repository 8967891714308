import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
	getBusiness: (id) => {
		return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`);
	},
	// getBranchList: (page = 1, limit = "50") => {
	// 	return SuperFetch(
	// 		`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
	// 	);
	// },
	getBranchList: (page = 1, limit = "50") => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
		);
	},
	getPaymentData: (id) => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/bank-info/details/${id}`
		);
	},
	updateUserBusiness: (id, query) => {
		return SuperFetch(`${baseUrl.business}/v4/businesses/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	updateBusinessLocation: (id, query) => {
		return SuperFetch(`${baseUrl.productAdmin}/seller/business/edit/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
};

export default Business;

import { useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";

export default function OurLocation() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='pb-[70px] md:pb-[120px] lg:pb-[160px]'>
			<SectionHeader title={ekkLanguage.landing.weAvailable} />
			<div>
				<img
					src='assets/images/our-location-en2.png'
					alt='location'
					className='m-auto'
				/>
			</div>
		</div>
	);
}

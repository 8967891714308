import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function BannerContent() {
	const ekkLanguage = useSelector((state) => state.lan);

	return (
		<div className='pt-[200px] lg:pt-[230px] banner-content-wrapper'>
			<h1
				className={`lg:text-48 md:text-36 text-24 font-extrabold  pr-[0px] pb-4 text-white text-center`}>
				{/* {ekkLanguage?.lan.landing.header1} */}
				Autonomous AI Agents for Business
			</h1>
			{/* <h1
				className={`lg:text-38 md:text-32 text-24  font-extrabold text-white  lg:text-262626-1000 text-center flex flex-wrap justify-center`}>
				{ekkLanguage?.lan.landing.header2}
			</h1> */}
			<div className='flex justify-center pt-8 pb-20'>
				{/* <a
					href='https://forms.office.com/r/RdhTRDjq75'
					target='_blank'
					rel='noreferrer'>
					<button
						// onClick={onClickPWADownload}
						className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn flex justify-center items-center`}>
						{ekkLanguage.lan.landing.apiBtn}
					</button>
				</a> */}
				<a
					href='https://outlook.office365.com/owa/calendar/AIAgentforBusiness@EkkBaz.com/bookings/'
					target='_blank'
					rel='noreferrer'
					className='ml-5'>
					<button
						// onClick={onClickPWADownload}
						className={`w-auto pointer demo-btn text-white text-20 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn flex justify-center items-center`}>
						Schedule Free Demo
					</button>
				</a>
				{/* <Link to='/login' rel='noreferrer' className='ml-5'>
					<button
						className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn flex justify-center items-center`}>
						{ekkLanguage.lan.landing.logIn}
					</button>
				</Link> */}
			</div>
			<div className='relative'>
				{/* <Transition
					native
					reset
					unique
					items={blockIndex}
					from={{
						opacity: 0,
						transform: "translate3d(30%,0,0)",
					}}
					enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
					leave={{
						opacity: 0,
						transform: "translate3d(-30%,0,0)",
					}}>
					{(index) => {
						if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<PhoneNoCheck
										countryCode={countryCode}
										setCountryCode={setCountryCode}
										country={country}
										setCountry={setCountry}
										phoneNo={phoneNo}
										setPhoneNo={setPhoneNo}
										sSize={sSize}
										setBlockIndex={setBlockIndex}
									/>
								</animated.div>
							);
						}
						if (index === authBox_Block.PASSWORD_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<Password
											setPassword={setPassword}
											loading={loading}
											setIsForgotPassword={setIsForgotPassword}
											password={password}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}
					}}
				</Transition> */}
			</div>
		</div>
	);
}

import { useSelector } from "react-redux";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionHeader from "./SectionHeader";
import "./style.scss";

let slideData = [
	{
		image: "assets/images/bots/bot1.png",
		name: "Bot Features",
	},
    {
		image: "assets/images/bots/bot2.png",
		name: "Bot Features",
	},
    {
		image: "assets/images/bots/bot3.png",
		name: "Bot Features",
	},
    {
		image: "assets/images/bots/bot4.png",
		name: "Bot Features",
	},
    {
		image: "assets/images/bots/bot5.png",
		name: "Bot Features",
	},
    {
		image: "assets/images/bots/bot6.png",
		name: "Bot Features",
	},
	
];
export default function BotFeatures() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	SwiperCore.use([Autoplay]);
	return (
		<div className='container m-auto pb-[40px] md:pb-[70px] lg:pb-[70px] bg-F2F3F7'>
			<SectionHeader title={ekkLanguage.landing.botFeatures} />
			<div>
				<Swiper
					modules={[Pagination]}
					pagination={{ clickable: true }}
					autoplay={{ delay: 2500, disableOnInteraction: false }}
					breakpoints={{
						280: {
							slidesPerView: 1,
							spaceBetween: 20,
						},
						320: {
							slidesPerView: 1,
							spaceBetween: 20,
						},
						599: {
							slidesPerView: 1,
							spaceBetween: 25,
						},
						768: {
							slidesPerView: 1,
							spaceBetween: 40,
						},
					}}>
					{slideData.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<div className='pb-10'>
									<img
										src={el.image}
										alt='partners'
										className='m-auto'
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
}

import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

export default function ImageUploader({
	files,
	setFiles,
	bHeight,
	placeHolder,
	placeHolder2,
	placeHolder3,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*,.pdf",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	return (
		<>
			{files?.length ? (
				<>
					<div className='flex justify-between relative items-center mb-[10px]'>
						<p className='text-16 font-bold text-black-1000'>{placeHolder}</p>
						<div
							onClick={() => {
								setFiles("");
							}}
							className={`${
								placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder &&
								"absolute right-0 bottom-4"
							} cursor-pointer`}
						>
							<EditBusinessProfile />
						</div>{" "}
					</div>
					<div
						style={{ width: "100%", height: bHeight }}
						className='flex justify-center items-center bg-E6E6E6 relative overflow-hidden'
					>
						<div style={{ height: bHeight }} className='text-center bg-white'>
							<img
								style={{ height: bHeight }}
								src={files[0].preview}
								alt='addImage'
								className='m-auto mb-2 object-contain'
							/>
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					{" "}
					<p className='text-16 font-bold mb-[10px] text-black-1000'>
						{placeHolder}
					</p>
					<div
						style={{ width: "100%", height: bHeight }}
						className='flex justify-center items-center bg-[#E9EFFF]  border-2 border-[#00000054] border-dashed'
					>
						<div
							className='text-center py-6 px-6 rounded-[5px] cursor-pointer'
							// style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}
						>
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								{placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder ? (
									<img
										src='assets/images/add-file.png'
										alt='addImage'
										className='m-auto mb-2'
									/>
								) : (
									<img
										src='assets/images/add-image.png'
										alt='addImage'
										className='m-auto mb-2'
									/>
								)}

								<p className='text-12 font-bold  text-[#4285F4]'>
									{placeHolder2}
								</p>
								<p className='text-12 font-normal  text-[#00000054]'>
									{placeHolder3}
								</p>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

import React from "react";
import { useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Backers() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let slideData = [
		{
			image: "assets/images/keystone.png",
			name: "Keystone",
		},
		{
			image: "assets/images/hatua.png",
			name: "Hatua",
		},
		{
			image: "assets/images/esing-logo.png",
			name: "easin-logo",
		},
		{
			image: "assets/images/artesin.png",
			name: "artesin",
		},
		// {
		//   image: "assets/images/sosv.png",
		//   name: "sosv",
		// },
	];
	return (
		<div className='container m-auto pb-[40px] md:pb-[50px] lg:pb-[50px] bg-F2F3F7 pt-[50px] lg:pt-[0]'>
			<SectionHeader title={ekkLanguage.landing.backers} />
			{/* <div className='flex flex-wrap justify-center items-center'>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className='mb-7 px-4'>
						<div className=' mb-[10px] flex justify-center items-center'>
							<img
								src='assets/images/keystone.png'
								alt='icon'
								className='object-contain'
							/>
						</div>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/hatua.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/esing-logo.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/artesin.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
				<div className='xl:w-[20%] lg:w-6/12 md:w-6/12 w-full'>
					<div className=' mb-[10px] flex justify-center items-center'>
						<img
							src='assets/images/sosv.png'
							alt='icon'
							className='object-contain'
						/>
					</div>
				</div>
			</div> */}
			<div>
				<Swiper
					modules={[Pagination]}
					pagination={{ clickable: true }}
					autoplay={{ delay: 2500, disableOnInteraction: false }}
					breakpoints={{
						280: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						320: {
							slidesPerView: 3,
							spaceBetween: 20,
						},
						599: {
							slidesPerView: 4,
							spaceBetween: 25,
						},
						768: {
							slidesPerView: 4,
							spaceBetween: 40,
						},
					}}>
					{slideData.map((el, i) => {
						return (
							<SwiperSlide key={i}>
								<div className='pb-10'>
									<img
										src={el.image}
										alt='partners'
										className='m-auto'
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
}

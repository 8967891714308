const english = {
	login: {
		contryLabel: "COUNTRY",
		contryPlaceHolder: "",
		phoneLable: "PHONE NUMBER",
		phonePlaceHolder: "PHONE NUMBER",
		validationLabel:
			"Phone number not valid, Please write phone no in english",
		btnLabel: "Continue",
		tokenSuccessLabel: "Your token has been sent to your phone",
		tokenSuccessFailed: "Your token not create yet please try again",
		langChange: "Change language to",
		termsAllow: "Please allow terms and conditions while logging in!",
	},
	password: {
		passwordLabel: "PASSWORD",
		passwordPlaceHolder: "Enter your password",
		forgetPass: "Forgot Password",
		backWord: "Go Back",
		btnLabel: "Login",
		otpSendLabel: "Code has been sent to your mobile",
		passError: "Password is not matched with phone number",
	},
	changePass: {
		passwordLabel: "NEW PASSWORD",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Password does not match",
		btnLabel: "Continue",
		successLabel: "Your password Changed successfully",
		errorLabel: "Your password Changed failed",
		lengthErrorLabel: "Password must be at least 4 characters long",
		notMatchLabel: "Your password and confirm password not match.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Enter your token",
		tokenResend: "Resend token",
		tokenSuccessLabel: "Code has been sent to your mobile, type to continue",
		tokenErrorLabel: "Token send failed",
		timerLabel: "Please wait",
		second: "second",
		btnLabel: "Continue",
		tokenNotMatch: "Token not match",
		tokenVarified: "Your token has been verified",
		tokenNotWrite: "Please enter your token",
	},
	registration: {
		nameLabel: "FULL NAME",
		namePlaceholder: "Enter your full name",
		createProfile: "Create Your Profile",
		helpUs: "Please help us by providing correct information",
		emailLabel: "EMAIL",
		emailPlaceholder: "Enter your email",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		validationLabel: "Input field must be at least 4 characters long",
		passNotMatch: "Your password and confirm password not match.",
		btnLabel: "Continue",
		successMessage: "Profile Created Successfully",
		errorMessage: "Please check valid input",
		passLengthValidLabel: "Password must be at least 4 characters long",
	},
	letNav: {
		homeMenuLabel: "Home",
		taskMenuLabel: "Task List",
		analyticsMenuLabel: "Analytics",
		referMenuLabel: "Refer List",
		orderMenuLabel: "Order History",
		mySupplierMenuLabel: "My Suppliers",
		businessProfileMenuLabel: "Business Profile Settings",
		phoneCall: "Call To Order",
	},
	jobSelect: {
		titlePart1: "Welcome to our EkkHero Community.",
		ekkHero: "EkkHero",
		titlePart2: " you can complete your task and ",
		earnMoney: "Earn money.",
		sales: "Sales",
		delivery: "Delivery",
		others: "Others",
		btnLabel: "Next",
		navLabel: "Select one or more",
	},
	home: {
		showMoreLabel: "Show More",
		registerBtnLabel: "Register",
		updateBtnLabel: "Update Information",
		brandLabel: "Brand",
		offerLabel: "New Offer",
		mySuppliersLabel: "My Suppliers",
		nearSuppliersLabel: "Nearby Suppliers",
		recommendentLabel: "Just For You",
		searchLabel: "Search products",
		todayPurchaseLabel: "Today’s Purchase",
		totalOrderLabel: "Products from",
		totalOrder: "Orders",
		skusLabel: "SKUs",
		tillNow: "Till Now",
		orderDue: "Orders Due",
		brandProductPageTitle: "All Brands",
		offerProductPageTitle: "Offer Products",
		offerProductNotFound: "Sorry, No offers were found for you.",
		mySupplierNotFound:
			"You dont have any suppliers. Please buy products to add your suppliers here.",
		supplierNotFound:
			"Sorry, no suppliers were found in your area, We will assign some suppliers in your area very soon.",
		brandSeller: "Suppliers",
		allBrandSeller: "All Suppliers",
		km: "km",
		step1Title: "Step 1 - Register as a EkkHero",
		step1Content:
			"Submit your information to be approved as EkkHero. You cannot apply to any job until you register.",
		step2Title: "Step 2 - Wait for your ID Verification",
		step2Content:
			"When finish your register wait fo your ID Verification. Registers button & profile will turn green when your id verification is done.",
		step3Title: "Step 3 - Apply for Task/Job",
		step3Content: "View the task/job list and apply for them.",
		recommendentionLabel: "Recommendations",
		applyNowBtnLabel: "Apply Now",
		clickInfoLabel: "Click green “Apply Now” button for do this job.",
		jobDetailsTitle: "Job/Task Details",
	},
	addressVerify: {
		nidPlaceholder1: "NID - Front Side",
		nidPlaceholder2: "NID - Back Side",
		nidPlaceholder3: "Upload your image here, or browse",
		nidPlaceholder4: "Maximum file size support 2MB, JPG, JPEG, PNG or PDF",
		mainTitle: "Account Verify",
		addressTitlePart1: "Please give your",
		addressTitlePart2: "Correct Information",
		presentAddressLabel: "Present Address",
		presentAddressPlaceholder: "Enter your full address",
		presentCityLabel: "Present City",
		presentCityPlaceholder: "City",
		postalCodeLabel: "Postal Code",
		postalCodePlaceholder: "Postal Code",
		nameLabel: "Name",
		namePlaceholder: "Enter your father/mother/brother name",
		numberLabel: "Number",
		numberPlaceholder: "Enter your father/mother/brother number",
		extraLabel: "for Emergency Contact",
		cancel: "I will give this information later",
		errorLabel: "Please provide all * marked fields info",
	},
	workVerify: {
		workVerifyTitle: "Work Type & Salary",
		workTypeLabel: "Work Type",
		workTypeOption1: "Part Time",
		workTypeOption2: "Full Time",
		previousWorkExperienceLabel: "Previous Work Experience",
		previousWorkExperienceOption1: "Yes",
		previousWorkExperienceOption2: "No",
		monthlySalaryLabel: "Monthly Salary",
		monthlySalaryLabel2: "Expected from EkkBaz",
		monthlySalaryPlaceholder: "Enter your expected monthly salary",
		workAreaLabel: "Work Area",
		workAreaLabel2: "Interested",
		workAreaPlaceholder: "Select your Interested work area",
		academicQualificationLabel: "Educational Qualification",
		academicQualificationLabel2:
			"Please Select Your Academic Education level",
		academiOption1: "Class 1 to 5 ( PSC )",
		academiOption2: "Class 1 to 8  ( JSC )",
		academiOption3: "Class 1 to 10 ( SSC )",
		academiOption4: "Class 1 to 12 ( HSC )",
		academiOption5: "Others",
		addFileLabel: "Add File or Media",
		addFileLabel2: "Your Previous Working Photo or CV",
		addFilePlaceholder: "Browse File",
	},
	skillVerify: {
		skillVerifyTitle: "Industry & Skill",
		industryLabel: "Working Industry",
		industryLabel2: "Please select your working industry",
		food: "Food",
		fashion: "Fashion",
		pharmacy: "Pharmacy",
		marketing: "Marketing",
		electronics: "Electronics",
		digitalServices: "Digital Services",
		it: "IT",
		design: "Design",
		banking: "Banking",
		insurance: "Insurance",
		cosmetics: "Cosmetics",
		logistics: "Logistics",
		skillLabel: "Skill",
		skillLabel2: "Please select your best skill",
		driving: "Strong driving record",
		navigation: "Navigation",
		customerService: "Customer Service",
		vehicle: "Vehicle maintenance knowledge",
		fitness: "Physical fitness & strength",
		problemSolving: "Problem Solving",
		traffic: "Knowledge of traffic laws",
		work: "Work alone effectively",
	},
	drivingVerify: {
		drivingTitle: "Driving & Vehicle",
		haveDrivingLabel: "Do you have any driving license?",
		licenceNumberLabel: "Driving licence number",
		licenceNumberPlaceholder: "Enter your driving license number",
		haveVehicleLabel: "Do you have any vehicles?",
		vehicleTypeLabel: "Select your vehicle type",
		loadAmountLabel: "Vehicle Load Amount",
		loadAmountLabel2: "(in KG)",
		loadAmountPlaceholder: "Enter your vehicle load amount",
		vehicleNumberLabel: "Vehicle Number",
		vehicleNumberPlaceholder: "Enter your vehicle number",
		truck: "Truck",
		car: "Car",
		motorcycle: "Motorcycle",
		escooter: "E-Scooter",
		bicycle: "Bicycle",
	},
	profile: {
		pageTitle: "Profile & Settings",
		contactMenuLable: "Contact & Basic Info",
		passwordMenuLable: "Change Password",
		logoutMenuLable: "Log Out",
		changeLanguageMenuLable: "Change Language",
		refer: "Refer",
		referNavTitle: "Referral Code",
		referTitle: "Here is your Referral Code",
		referSubtitle: "Please Share it with your Customers",
		referList: "Refer List",
		referModalTitle:
			"Are you sure you want to remove this customer from your refer?",
		yes: "Yes",
		no: "No",
	},
	changeLanguages: {
		pageTitle: "Select Language",
		btnLabel: "Save",
	},
	contact: {
		pageTitle: "Contact & Basic Info",
		emailLabel: "Email",
		emailPlaceholder: "Enter your email",
		nidLabel: "NID",
		nidPlaceholder: "Enter NID number",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "please enter current email address",
	},
	otpsend: {
		pageTitle: "Verify Number",
		btnLabel: "Send",
		message: "Tap to send authentication code to your mobile via SMS.",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
	},
	otpsubmit: {
		pageTitle: "Verify Number",
		btnLabel: "Submit",
		title: "Verification Code",
		paragraph: "We have sent the verification code to Your Mobile Number",
		otpLabel: "OTP",
		otpPlaceholder: "Enter your OTP code",
		receiveYetLabel: "Didn’t recieve code?",
		againRequestLabel: "Request again",
		timerLabel: "Please wait",
		second: "second",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
		tokenVarifiedMessage: "Your token has been verified",
		validationLabel: "Please submit your OTP",
	},
	changePassword: {
		pageTitle: "Change Password",
		btnLabel: "Submit",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		successLabel: "Your password Updated.",
		errorLabel: "Your password Updated Failed",
		passNotMatchLabel: "Your password and confirm password not match.",
		changePassSuccessPara: "Your password has been Changed Successfully.",
		changePassContinueBtn: "Continue",
	},

	locationPicker: {
		pageTitle: "Search The Store Location",
		btnLabel: "CONFIRM",
		paragraph: `Move map to your business location to select it`,
	},
	location: {
		title: "Enable your Location",
		paragraph:
			"This app requires that location services are turned on your device and for this app. You must enabel them is Setting before using this app.",
		btnLabel: "Allow only while using the app",
	},

	pwaDownload: {
		header: "Download EkkBaz App",
		paragraph:
			"Download the app and order bulk from your supplier any time from now on.",
		cancelBtn: "Cancel",
		downloadBtn: "Download",
	},

	landing: {
		header1: "Transform your procurement team ",
		header2: "with Agentic Systems.",
		header3: " ",
		login: "Log in",
		bangla: "Bangla",
		english: "English",
		retail: "Retail",
		manufacture: "Supplier",
		hotline: "Hot line",
		phoneNumber: "Phone Number",
		phoneNumberPlaceHolder: "Enter Phone Number",
		fAbout: "Wholesale market at your fingertip. Buy Now, Pay Later!",
		location: "Location",
		singapore: "Singapore Office",
		bangladesh: "Bangladesh Office",
		bOfficeAddress: "13th Floor, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Other info",
		partners: "Our Partners",
		news: "News",
		support: "Support",
		corporate: "Corporate",
		refundPolicy: "Refund Policy",
		termsCondition: "Terms Conditions",
		privacyPolicy: "Privacy Policy",
		contactUs: "Contact Us",
		messageUs: "Message Us",
		facebook: "Facebook",
		sales: "Sales",
		media: "Media",
		downloadBtn: "Download App",
		apiBtn: "Join Waitlist",
		backers: "Our Backers",
		benefits: "Your Benefits",
		discover: "Latest EkkBaz News",
		benefitsOne: "24 hours on-time delivery",
		benefitsDisOne: "",
		benefitsTwo: "Best market prices",
		benefitsDisOneTwo: "",
		benefitsThree: "Largest SKUs assortments",
		benefitsDisOneThree: "",
		benefitsFore: "Financing supports for purchases",
		benefitsDisOneFore: "",
		seeMore: "See More",
		weAvailable: "We are Available",
		ourTeam: "Our Team",
		impact: "Impact",
		botFeatures: "Bot Features",
	},
	others: {
		alert: "Alert",
		backButtonMessage:
			"Your cart have some items, if you leave this page your cart will be empty.",
		okBtnLabel: "OK",
	},
};
export default english;

import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCheckbox,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import { getLanguage } from "config/language";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo, setUser } from "redux/container/userSlice";
import { toast } from "react-toastify";
import Auth from "services/Auth";

export default function SkillVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// industry specialization
	const [food, setFood] = useState(false);
	const [fashion, setFashion] = useState(false);
	const [pharmacy, setPharmacy] = useState(false);
	const [marketing, setMarketing] = useState(false);
	const [electronics, setElectronics] = useState(false);
	const [digitalServices, setDigitalServices] = useState(false);
	const [IT, setIT] = useState(false);
	const [design, setDesign] = useState(false);
	const [banking, setBanking] = useState(false);
	const [insurance, setInsurance] = useState(false);
	const [cosmetics, setCosmetics] = useState(false);
	const [logistics, setLogistics] = useState(false);
	// skills
	const [drivingSkill, setDrivingSkill] = useState(false);
	const [navigationSkill, setNavigationSkill] = useState(false);
	const [customerService, setCustomerService] = useState(false);
	const [vehicleMaintainance, setVehicleMaintainance] = useState(false);
	const [fitness, setFitness] = useState(false);
	const [problemSolving, setProblemSolving] = useState(false);
	const [trafficLaw, setTrafficLaw] = useState(false);
	const [workAlone, setWorkAlone] = useState(false);

	const handleSubmit = () => {
		const skillData = {
			industry_specialisation: `${food ? "Food," : ""}${
				fashion ? " Fashion," : ""
			}${pharmacy ? " Pharmacy," : ""}${marketing ? " Marketing," : ""}${
				electronics ? " Electronics," : ""
			}${digitalServices ? " Digital Services," : ""}${IT ? " IT," : ""}${
				design ? " Design," : ""
			}${banking ? " Banking," : ""}${insurance ? " Insurance," : ""}${
				cosmetics ? " Cosmetics," : ""
			}${logistics ? " Logistics," : ""}`,
			skills: `${drivingSkill ? "Strong Driving Record," : ""}${
				navigationSkill ? " Navigation," : ""
			}${customerService ? " Customer Service," : ""}${
				vehicleMaintainance ? " Vehicle Maintenance Knowledge," : ""
			}${fitness ? " Physical Fitness & Strength," : ""}${
				problemSolving ? " Problem Solving," : ""
			}${trafficLaw ? " Knowledge of Traffic Laws," : ""}${
				workAlone ? "Work Alone Effectively" : ""
			}`,
		};
		console.log(skillData);
		Auth.setUserInfo(skillData).then((res) => {
			console.log(res);
			if (res.success) {
				dispatch(setAllInfo(res.data));
				toast.success("Industry & Skill Saved Successfully!");
				navigate("/driving-verify");
			}
		});
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButton>
					<div className='pt-5 px-5'>
						{/* page title  */}
						<h6 className='text-18 text-center font-bold text-success mb-[20px]'>
							{ekkLanguage.skillVerify.skillVerifyTitle}{" "}
						</h6>

						{/* Working History */}
						<>
							<IonLabel className='flex items-center font-bold mt-7 mb-[11px]'>
								<img
									className='mr-[10px] h-6 w-6'
									src='/assets/images/ekkhero/Icons/skill-verify/work-history.png'
									alt='work'
								></img>{" "}
								<div>
									<span>{ekkLanguage.skillVerify.industryLabel} </span>
									<p className='font-extralight'>
										{ekkLanguage.skillVerify.industryLabel2}
									</p>
								</div>
							</IonLabel>
							{/* food  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!food && "checkbox-overlay"
								} ${food && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={food}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setFood(!food)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/food.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.food}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* fashion  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!fashion && "checkbox-overlay"
								} ${fashion && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={fashion}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setFashion(!fashion)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/fashion.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.fashion}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* pharmacy  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!pharmacy && "checkbox-overlay"
								} ${pharmacy && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={pharmacy}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setPharmacy(!pharmacy)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/pharmacy.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.pharmacy}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* marketing  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!marketing && "checkbox-overlay"
								} ${marketing && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={marketing}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setMarketing(!marketing)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/marketing.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.marketing}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* electronics  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!electronics && "checkbox-overlay"
								} ${electronics && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={electronics}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setElectronics(!electronics)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/electronics.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.electronics}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* digital services  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!digitalServices && "checkbox-overlay"
								} ${digitalServices && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={digitalServices}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setDigitalServices(!digitalServices)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/digital-services.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.digitalServices}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* IT  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!IT && "checkbox-overlay"
								} ${IT && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={IT}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setIT(!IT)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/it.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.it}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* design  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!design && "checkbox-overlay"
								} ${design && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={design}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setDesign(!design)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/design.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.design}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* banking */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!banking && "checkbox-overlay"
								} ${banking && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={banking}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setBanking(!banking)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/banking.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.banking}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* insurance  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!insurance && "checkbox-overlay"
								} ${insurance && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={insurance}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setInsurance(!insurance)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/insurance.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.insurance}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* cosmetics  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!cosmetics && "checkbox-overlay"
								} ${cosmetics && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={cosmetics}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setCosmetics(!cosmetics)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/cosmetics.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.cosmetics}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* logistics  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!logistics && "checkbox-overlay"
								} ${logistics && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={logistics}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setLogistics(!logistics)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/logistics.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.logistics}
										</p>
									</span>
								</IonCheckbox>
							</div>
						</>

						{/* Skills */}
						<>
							<IonLabel className='flex items-center  mt-7 mb-[11px]'>
								<img
									className='mr-[10px] h-6 w-6'
									src='/assets/images/ekkhero/Icons/skill-verify/skills.png'
									alt='work'
								></img>{" "}
								<div>
									<span className='text-16 font-bold leading-5'>
										{ekkLanguage.skillVerify.skillLabel}{" "}
									</span>
									<p className='font-extralight text-14'>
										{ekkLanguage.skillVerify.skillLabel2}
									</p>
								</div>
							</IonLabel>
							{/* strong driving  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!drivingSkill && "checkbox-overlay"
								} ${drivingSkill && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={drivingSkill}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setDrivingSkill(!drivingSkill)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/driving.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.driving}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* navigation  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!navigationSkill && "checkbox-overlay"
								} ${navigationSkill && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={navigationSkill}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setNavigationSkill(!navigationSkill)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/navigation.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.navigation}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* customer service  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!customerService && "checkbox-overlay"
								} ${customerService && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={customerService}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setCustomerService(!customerService)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/customer-service.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.customerService}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* vehicle maintenance */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!vehicleMaintainance && "checkbox-overlay"
								} ${vehicleMaintainance && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={vehicleMaintainance}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() =>
										setVehicleMaintainance(!vehicleMaintainance)
									}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/vehicle.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.vehicle}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* Fitness */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!fitness && "checkbox-overlay"
								} ${fitness && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={fitness}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setFitness(!fitness)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/fitness.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.fitness}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* Problem Solving  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!problemSolving && "checkbox-overlay"
								} ${problemSolving && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={problemSolving}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setProblemSolving(!problemSolving)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/problem-solving.png'
											alt=''
										/>
										<p className='text-14'>
											{ekkLanguage.skillVerify.problemSolving}
										</p>
									</span>
								</IonCheckbox>
							</div>
							{/* Traffic Laws  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!trafficLaw && "checkbox-overlay"
								} ${trafficLaw && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={trafficLaw}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setTrafficLaw(!trafficLaw)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/traffic-law.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.traffic}</p>
									</span>
								</IonCheckbox>
							</div>
							{/* Work Alone  */}
							<div
								className={`w-full h-[50px] border rounded flex items-center bg-white mb-[10px] ${
									!workAlone && "checkbox-overlay"
								} ${workAlone && "border-2 border-success "}`}
							>
								<IonCheckbox
									checked={workAlone}
									className='pl-[16px] font-bold custom-checkbox'
									color='success'
									onIonChange={() => setWorkAlone(!workAlone)}
									labelPlacement='end'
								>
									<span className='flex'>
										<img
											className='ml-2 mr-[10px] w-5'
											src='/assets/images/ekkhero/Icons/skill-verify/work-alone.png'
											alt=''
										/>
										<p className='text-14'>{ekkLanguage.skillVerify.work}</p>
									</span>
								</IonCheckbox>
							</div>
						</>

						{/* next button  */}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-8'
						>
							{ekkLanguage.jobSelect.btnLabel}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						{/* skip button  */}
						<IonButton
							onClick={() => {
								navigate("/driving-verify");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 mt-5 w-full  border-primary'
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

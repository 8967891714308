import React, { Component } from "react";
import PropTypes from "prop-types";

export class Input extends Component {
	static defaultProps = {
		label: "",
		errorMessage: "",
		placeholder: "",
	};
	render() {
		const { label, source, placeholder, errorMessage, ...inputExtras } =
			this.props;
		return (
			<div className='mb-1'>
				{typeof label === "function" ? (
					label()
				) : (
					<label className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{label}
					</label>
				)}
				{typeof label === "object" && (
					<label className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{`${(<img src={source} alt='' />)} ${label}}`}
					</label>
				)}
				<input
					placeholder={placeholder}
					className='appearance-none block w-full bg-grey-lighter text-grey-darker border   rounded py-2 px-2 mb-4 leading-tight focus:outline-none focus:bg-white'
					{...inputExtras}
				/>

				{errorMessage && <p className='text-red text-xs '>errorMessage </p>}
			</div>
		);
	}
}

Input.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

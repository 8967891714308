import React, { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import { Link, NavLink, useLocation } from "react-router-dom";
import { digitChanger, hasActive } from "helpers/helper";
import "./styles/_SidebarComponent.scss";
import {
	ProSidebar,
	Menu,
	MenuItem,
	SidebarFooter,
	SidebarHeader,
	SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getLocalUser } from "config/user";
import Modal from "react-modal";
import { setPwaDownloadData } from "redux/container/userSlice";
import { logout } from "redux/container/authSlice";
import SignOut from "assets/images/icon/svg/SignOut";

const LeftSidebar = ({ isMobile, menuCollapse, menuIconClick }) => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let { user: userData } = getLocalUser();
	let { associated_businesses: checkAdmin } = getLocalUser();
	const ekkJob = useSelector((state) => state.user.selectedJob);

	useEffect(() => {
		Modal.setAppElement("body");
		Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handler = (e) => {
		e.preventDefault();
		dispatch(setPwaDownloadData(e));
	};
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, [pathname, handler]);

	return (
		<>
			<div
				onClick={menuIconClick}
				className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
					menuCollapse
						? "opacity-0 invisible absolute"
						: "opacity-40 visible fixed"
				}`}
			></div>
			<div
				className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
				style={
					menuCollapse
						? {
								width: 0,
								zIndex: 1000000,
						  }
						: {
								width: "300px",
								zIndex: 1000000,
						  }
				}
			>
				<div id='sidebar-scrollbar'>
					<span
						className='absolute top-2 right-2 bg-primary text-white p-1 rounded-[5px]'
						style={{ zIndex: 1111, lineHeight: 1 }}
						onClick={menuIconClick}
					>
						<i className='material-icons'>close</i>
					</span>
					<nav className='sidebar-menu'>
						<ProSidebar collapsed={menuCollapse} className='relative'>
							{!menuCollapse && (
								<SidebarHeader>
									<Link to='/profile'>
										<div
											className='logo-box flex items-center pt-[30px] px-5 pb-5'
											// style={{
											// 	background:
											// 		"url(assets/images/sidebarBg.png)",
											// 	position: "relative",
											// }}
										>
											<div className='user-img mr-[10px] '>
												{userData?.profile_image ? (
													<img
														src={userData?.profile_image}
														alt='avatar'
														className='h-[80px] w-[80px] rounded-full'
													/>
												) : (
													<img
														src={userData?.profile_image}
														alt='user_avater'
														className='h-[80px] w-[80px] rounded-full'
													/>
												)}
											</div>
											<div className='user-info'>
												<p className='text-16 font-semibold text-222222 pb-[5px]'>
													{userData?.full_name}
												</p>
												<p className='text-10 pb-[5px]'>
													{digitChanger(userData?.PhoneNumber)}
												</p>

												{ekkJob && (
													<p className='text-10 pb-[5px]'>
														{ekkJob.sales && "Sales"}{" "}
														{ekkJob.delivery && "Delivery"}{" "}
														{ekkJob.others && "Others"}
													</p>
												)}

												<div className='flex'>
													<p
														className={`text-12 pb-[5px] font-bold ${
															userData?.kyc_status === "Unverified" &&
															"text-primary"
														}  ${
															userData?.kyc_status === "Verified" &&
															"text-success"
														} `}
													>
														{userData?.kyc_status}
													</p>

													{checkAdmin[0]?.role && (
														<p
															className={`text-12 font-semibold pb-[5px] ml-2 text-[#00000080]`}
														>
															{checkAdmin[0]?.role}
														</p>
													)}
												</div>
											</div>
										</div>
									</Link>
								</SidebarHeader>
							)}
							<SidebarContent>
								<Menu iconShape='square'>
									<MenuItem
										icon={<i className='material-icons'>home</i>}
										className={hasActive("/home", pathname)}
									>
										{ekkLanguage.letNav.homeMenuLabel}
										<NavLink to={`/home`} />
									</MenuItem>
									<MenuItem
										icon={<i className='material-icons'>assignment</i>}
										className={hasActive("/task-list", pathname)}
									>
										{ekkLanguage.letNav.taskMenuLabel}
										<NavLink to={`/task-list`} />
									</MenuItem>
									<MenuItem
										// icon={<i className='material-icons'>analytic</i>}
										icon={
											<img
												src='/assets/images/ekkhero/Icons/fi-rr-chart-histogram.png'
												alt=''
											></img>
										}
										className={hasActive("/analytics", pathname)}
									>
										{ekkLanguage.letNav.analyticsMenuLabel}
										<NavLink to={`/analytics`} />
									</MenuItem>
									<MenuItem
										// icon={<i className='material-icons'>analytic</i>}
										icon={
											<img
												src='/assets/images/ekkhero/Icons/refer-list2.png'
												alt=''
											></img>
										}
										className={hasActive("/refer-list", pathname)}
									>
										{ekkLanguage.letNav.referMenuLabel}
										<NavLink to={`/refer-list`} />
									</MenuItem>
								</Menu>
							</SidebarContent>
							<SidebarFooter>
								<div
									onClick={() => {
										dispatch(logout());
									}}
									className='my-2 text-center cursor-pointer'
								>
									<div className='flex items-center justify-center'>
										<span
											className='bg-F3F3F3 flex justify-center items-center mr-4 rounded-[10px]'
											style={{ height: 40, width: 40 }}
										>
											<SignOut />
										</span>
									</div>
								</div>
							</SidebarFooter>
						</ProSidebar>
					</nav>
				</div>
			</div>
		</>
	);
};

export default LeftSidebar;

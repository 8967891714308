import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Search from "assets/images/icon/svg/Search";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { digitChanger, monthChanger } from "helpers/helper";

function ReferList() {
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const [searchQ, setSearchQ] = useState("");
	const [finalSearched, setFinalSearched] = useState("");
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const deleteQueryHandler = async (e) => {
		setSearchQ("");
		setFinalSearched("");
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}
				>
					<BackButtonTitle
						title={ekkLanguage.letNav.referMenuLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<div className='mt-[70px] px-5'>
						<div className='relative'>
							<span
								onClick={() => {
									console.log(searchQ);
									setFinalSearched(searchQ);
								}}
								className='absolute left-3 flex items-center w-5 rounded-[7px] h-full z-10  cursor-pointer'
							>
								<Search color='rgba(0,0,0,.6)' />
							</span>
							<input
								className='w-full h-11 rounded-[7px] pl-12 search-input border'
								placeholder='Search'
								style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
								value={searchQ}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										console.log(searchQ);
										setFinalSearched(searchQ);
									}
								}}
								onChange={(e) => setSearchQ(e.target.value)}
							></input>
							{searchQ && (
								<span
									className='absolute  right-3 top-[34%] h-full z-10 cursor-pointer'
									onClick={deleteQueryHandler}
								>
									<Cross />
								</span>
							)}
						</div>{" "}
						{finalSearched && (
							<p className='mt-1 font-light text-12'>
								Searched for :{" "}
								<span className='font-bold'>{finalSearched}</span>
							</p>
						)}
						{/* user 1  */}
						<div className='w-full flex  shadow-inner rounded px-[10px] bg-white mt-[15px] py-[10px]'>
							<div className='flex'>
								<img
									className='w-10 h-10 mr-[15px]'
									src='/assets/images/ekkhero/Icons/store1.png'
									alt='store'
								/>
								<div className='flex flex-col'>
									<h6 className='font-bold text-14 leading-5'>
										Robin Enterprise
									</h6>
									<span className='text-[#00000080] text-10 leading-[14px]'>
										<p>{`${digitChanger("+8801710346426")} | ${digitChanger(
											`18 ${monthChanger("Jun")}, 2022`
										)}`}</p>
									</span>
								</div>
							</div>
						</div>
						{/* user 2  */}
						<div className='w-full flex  shadow-inner rounded px-[10px] bg-white mt-[10px] py-[10px]'>
							<div className='flex'>
								<img
									className='w-10 h-10 mr-[15px]'
									src='/assets/images/ekkhero/Icons/store2.png'
									alt='store'
								/>
								<div className='flex flex-col'>
									<h6 className='font-bold text-14 leading-5'>Shohan</h6>
									<span className='text-[#00000080] text-10 leading-[14px]'>
										<p>{`${digitChanger("+8801710346426")} | ${digitChanger(
											`18 ${monthChanger("Jun")}, 2022`
										)}`}</p>
									</span>
								</div>
							</div>
						</div>
						{/* user 3  */}
						<div className='w-full flex  shadow-inner rounded px-[10px] bg-white mt-[10px] py-[10px]'>
							<div className='flex'>
								<img
									className='w-10 h-10 mr-[15px]'
									src='/assets/images/ekkhero/Icons/store1.png'
									alt='store'
								/>
								<div className='flex flex-col'>
									<h6 className='font-bold text-14 leading-5'>Faisal Ahmed</h6>
									<span className='text-[#00000080] text-10 leading-[14px]'>
										<p>{`${digitChanger("+8801710346426")} | ${digitChanger(
											`18 ${monthChanger("Jun")}, 2022`
										)}`}</p>
									</span>
								</div>
							</div>
						</div>
						{/* user 4  */}
						<div className='w-full flex  shadow-inner rounded px-[10px] bg-white mt-[10px] py-[10px]'>
							<div className='flex'>
								<img
									className='w-10 h-10 mr-[15px]'
									src='/assets/images/ekkhero/Icons/store2.png'
									alt='store'
								/>
								<div className='flex flex-col'>
									<h6 className='font-bold text-14 leading-5'>Imran Hossain</h6>
									<span className='text-[#00000080] text-10 leading-[14px]'>
										<p>{`${digitChanger("+8801710346426")} | ${digitChanger(
											`18 ${monthChanger("Jun")}, 2022`
										)}`}</p>
									</span>
								</div>
							</div>
						</div>
						{/* user 5  */}
						<div className='w-full flex  shadow-inner rounded px-[10px] bg-white mt-[10px] py-[10px]'>
							<div className='flex'>
								<img
									className='w-10 h-10 mr-[15px]'
									src='/assets/images/ekkhero/Icons/store1.png'
									alt='store'
								/>
								<div className='flex flex-col'>
									<h6 className='font-bold text-14 leading-5'>Moqbul</h6>
									<span className='text-[#00000080] text-10 leading-[14px]'>
										<p>{`${digitChanger("+8801710346426")} | ${digitChanger(
											`18 ${monthChanger("Jun")}, 2022`
										)}`}</p>
									</span>
								</div>
							</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default ReferList;

import { IonButton } from "@ionic/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Recommendation() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	return (
		<div className='my-10 px-5'>
			<div className='flex justify-start'>
				<img src='/assets/images/ekkhero/Icons/recommend 1.png' alt='' />
				<h6 className='font-bold text-20 ml-2'>
					{ekkLanguage.home.recommendentionLabel}
				</h6>
			</div>
			<div className='mt-3 shadow-md px-2 pb-4 rounded-md'>
				<img
					onClick={() => {
						navigate("/job-details");
					}}
					className='rounded-lg w-full h-40 cursor-pointer'
					src='/assets/images/ekkhero/job-poster.png'
					alt='job-poster'
				/>
				<div className='mt-[10px] '>
					<p
						onClick={() => {
							navigate("/job-details");
						}}
						className='font-bold text-16 '
					>
						Paid Internship Opportunity at EkkBaz!
					</p>
					<p
						onClick={() => {
							navigate("/job-details");
						}}
						className='text-justify leading-4 mt-[10px]'
					>
						Paid internship opportunity at EkkBaz (for Freshers). We are
						actively hiring! EkkBaz Bangladesh is offering a truly ambitious
						caree opportunity for those who are driven to make a real impact in
						the world.{" "}
						<span
							onClick={() => {
								navigate("/job-details");
							}}
							className='text-success cursor-pointer'
						>
							Read more...
						</span>
					</p>
					<p className='font-semibold mt-[5px]'>
						{ekkLanguage.home.clickInfoLabel}
					</p>
					<div className='flex justify-between mt-5'>
						<p className='font-bold text-primary'>End Date: 28 May 2023</p>
						<p className='font-bold text-primary'>20 Candidates</p>
					</div>
					<IonButton
						onClick={() => {
							navigate("/home");
						}}
						color='success'
						className='font-bold h-10 w-full mt-4'
					>
						{ekkLanguage.home.applyNowBtnLabel}
						<span className='pl-2'>
							<img src='/assets/images/ekkhero/Icons/apply.svg' alt='apply' />
						</span>
					</IonButton>
				</div>
			</div>
			<div className='mt-7 shadow-md px-2 pb-4 rounded-md'>
				<img
					className='rounded-lg w-full h-40 cursor-pointer'
					src='/assets/images/ekkhero/job-poster.png'
					alt='job-poster'
				/>
				<div className='mt-[10px] '>
					<p
						onClick={() => {
							navigate("/job-details");
						}}
						className='font-bold text-16 '
					>
						Paid Internship Opportunity at EkkBaz!
					</p>
					<p
						onClick={() => {
							navigate("/job-details");
						}}
						className='text-justify leading-4 mt-[10px]'
					>
						Paid internship opportunity at EkkBaz (for Freshers). We are
						actively hiring! EkkBaz Bangladesh is offering a truly ambitious
						caree opportunity for those who are driven to make a real impact in
						the world.{" "}
						<span
							onClick={() => {
								navigate("/job-details");
							}}
							className='text-success cursor-pointer'
						>
							Read more...
						</span>
					</p>
					<p className='font-semibold mt-[5px]'>
						{ekkLanguage.home.clickInfoLabel}
					</p>
					<div className='flex justify-between mt-5'>
						<p className='font-bold text-primary'>End Date: 28 May 2023</p>
						<p className='font-bold text-primary'>10 Candidates</p>
					</div>
					<IonButton
						onClick={() => {
							navigate("/home");
						}}
						color='success'
						className='font-bold h-10 w-full mt-4'
					>
						{ekkLanguage.home.applyNowBtnLabel}
						<span className='pl-2'>
							<img src='/assets/images/ekkhero/Icons/apply.svg' alt='apply' />
						</span>
					</IonButton>
				</div>
			</div>
		</div>
	);
}

export default Recommendation;
